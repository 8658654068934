import React from "react";
import styled, { DefaultTheme } from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "gatsby";

import { Title, Section, Box, Text, Button } from "../../components/Core";

// @ts-expect-error false positive image import failure
import imgContent1 from "../../assets/image/jpeg/easy-image-2-1.jpg";
// @ts-expect-error false positive image import failure
import imgContent2 from "../../assets/image/jpeg/easy-image-2-2.jpg";

interface ThemeProps {
  theme: DefaultTheme;
}

const ContentImg = styled(Box)<ThemeProps>`
  box-shadow: ${({ theme }) => `0 42px 54px ${theme.colors.shadow}`};
  border-radius: 10px;
  overflow: hidden;
  max-height: 515px;
`;

const Content = () => (
  <>
    <Section>
      <Container>
        <Row className="justify-content-center pb-4">
          <Col lg="6">
            <Title variant="hero">
              Our mission is to make your life easier.
            </Title>
          </Col>
          <Col lg="6" className="pl-lg-5 pt-lg-4">
            <Text>
              Resilient communication systems designed for safety and
              productivity. Combining digital with value added features such as
              GPS, internal positioning and man down/ lone worker support.
            </Text>
            <Link to="/contact">
              <Button mt={4} variant="solid" color="light" mr={3}>
                Contact Us
              </Button>
            </Link>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default Content;
