import React from "react";
import { Helmet } from "react-helmet"

import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/shopping-centres/Hero";
import Content from "../sections/shopping-centres/Content";
import CaseList2 from "../sections/shopping-centres/CaseList2";
import CTA from "../sections/shopping-centres/CTA";
import Feature from "../sections/shopping-centres/Feature";

const About = () => {
  return (
    <>
      <PageWrapper headerDark>
        <Helmet>
          <title>Communication Solutions for Shopping Centre &amp; Parks</title>
          <meta name="description" content="A leading supplier of ShopWatch &amp; TownLink radio systems, ShopSafe offer effective and affordable crime reduction radio schemes throughout the UK." />
        </Helmet>
        <Hero title="Shopping Centre &amp; Parks" />
        <Content />
        <CaseList2 />
        <Feature />
        <CTA />
      </PageWrapper>
    </>
  );
};
export default About;
